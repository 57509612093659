<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>榜单颁奖</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">榜单</a></li>
        <li>颁奖</li>
      </ol>
    </div>


        <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>登录详情</h3>-->
<!--      </div>-->
      <div class="box-body">
        <table class="view">
          <tr>
            <th>编号：</th>
            <td>{{item.id}}</td>
            <th>状态：</th>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
          </tr>
          <tr>
            <th>名称：</th>
            <td>{{item.name}}</td>
            <th>人数：</th>
            <td>{{item.number}}</td>
          </tr>
          <tr>
            <th>开始时间：</th>
            <td>{{item.begin.format('yyyy-MM-dd')}}</td>
            <th>结束时间：</th>
            <td>{{item.end.format('yyyy-MM-dd')}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="box">
     <div class="box-head">
       <h3>名单列表</h3>
     </div>
      <div class="box-body">
          <a-empty v-if="lists.length == 0" />
          <table class="list" v-if="lists.length > 0">
          <tr>
            <!-- <th style="width: 120px;">#</th> -->
            <th style="width: 120px;">排名</th>
            <th style="width: 120px;">得分</th>
            <th>用户</th>
            <th>手机号</th>
            <th>角色</th>
            <th style="width: 120px;">奖金</th>
            <th style="width: 120px;">状态</th>
          </tr>
          <tr v-for="item in lists">
            <!-- <td>{{item.id}}</td> -->
            <td>
              <div>{{item.ranking}}</div>
            </td>
            <td>
              <div>{{item.score}}</div>
            </td>
            <td>
              <div>{{item.user.nickname}}</div>
            </td>
            <td>
              <div v-if="item.user.mobile">{{item.user.mobile}}</div>
              <div v-else>空</div>
            </td>
            <td>
              <div>{{item.role.name}}</div>
            </td>
           
            <td>
              <a-input ref="name" type="text" v-model="item.bonus" placeholder="奖金" />
            </td>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>

          <div class="submit">
            <a-button type="primary" html-type="submit">确认颁奖</a-button>
          </div>

        </a-form-model>

  </div>
</template>


<script>
export default {
  name: 'SysOplogDetail',
  data() {
    return {
      item: {
        staff: {}, records: [], state:{},
        begin: new Date(), end: new Date(),
        changed: new Date(), created: new Date()
      },
      lists: [],

      form: {
          layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
          },
          rules: {
            // name: [{ required: true, message: '名称不可为空' }],
          },
          data: {
            
          }
        }
    }
  },
  mounted() {
    this.request();
  },

  methods: {
    request() {
      let id = this.$route.params.id;
      this.$get('/test/rank/detail/' + id).then(res => {
        let ajax = res.data;
        if (ajax.code == 0 && ajax.data) {
          this.$util.timeFields(ajax.data.item);
          this.item = ajax.data.item;
          this.lists = ajax.data.lists;
        }
      })
    },

      onSubmit(){
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.submiting();
          }
        });
      },
      submiting (){
        let data = { id: this.item.id };
        data.lists = this.lists.map(list=>{
          return { id: list.id, bonus: list.bonus }
        })

        this.$confirm('确认要完成颁奖吗？', ()=>{
          let id = this.$route.params.id;
          this.$post('/test/rank/award/'+id, data).then(res => {
            let ajax = res.data;
            if (ajax.code == 0) {
              setTimeout(()=>{
                this.$router.go(-1);
              }, 500);

            }
          })
        })
      }
  }
}
</script>

<style scoped lang="less">
</style>